.item-condidature {
    background-color: #5090C1;
    margin-bottom: 15px;
    color: white;
    font-weight: bold;
    width: 300px !important;
    display: block;
}
.item-condidature-choix{
    background-color: #5090C1;
    margin-bottom: 15px;
    color: white;
    font-weight: bold;


    display: block;

}
.item-condidature-sujet{
    background-color: #5090C1;
    margin-bottom: 15px;
    color: white;
    font-weight: bold;
    width: 300px !important;
    cursor: move;
    display: block;
    padding: 5px;
}
.item-condidature-v{
    background-color: green;
    margin-bottom: 15px;
    color: white;
    font-weight: bold;
    display: block;
}
.item-sujet-choix{
    background-color: #5090C1;
    margin-bottom: 15px;
    color: white;
    font-weight: bold;
    display: block;
    padding: 5px;
    cursor: move;
}
.item-condidature-choix:active{
    background-color: orange;
}
.css-10hburv-MuiTypography-root{
    font-weight: 900 !important;
}
.minimal {

    /* styling */
    background-color: white;
    border: thin solid gray;
    border-radius: 4px;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;

    /* reset */

    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
}
select.minimal {
    background-image:
            linear-gradient(45deg, transparent 50%, gray 50%),
            linear-gradient(135deg, gray 50%, transparent 50%),
            linear-gradient(to right, #ccc, #ccc);
    background-position:
            calc(100% - 20px) calc(1em + 2px),
            calc(100% - 15px) calc(1em + 2px),
            calc(100% - 2.5em) 0.5em;
    background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
    background-repeat: no-repeat;
}

select.minimal:focus {
    background-image:
            linear-gradient(45deg, green 50%, transparent 50%),
            linear-gradient(135deg, transparent 50%, green 50%),
            linear-gradient(to right, #ccc, #ccc);
    background-position:
            calc(100% - 15px) 1em,
            calc(100% - 20px) 1em,
            calc(100% - 2.5em) 0.5em;
    background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
    background-repeat: no-repeat;
    border-color: green;
    outline: 0;
}


select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}
