#div_amoe{

    overflow-x: auto;
}
td {
    font-size: 16px;
    padding-right: 20px;

}
th {
    font-size: 14px;
    font-weight: bold;
}